@import "overrides";
@import "~bootstrap/scss/bootstrap.scss";

@import "buttons";

@import "GameStartCountdown";
@import "Minigame";

@import "minigames/MathMinigame";
@import "minigames/StroopEffectMinigame";
@import "minigames/OperatorMathMinigame";
@import "minigames/UnscrambleMinigame.scss";
@import "minigames/DialMinigame.scss";

.App {
  text-align: center;
  font-family: map-get($fredbox-fonts, "base");

  >main {
    @extend .container-sm;
  }
}

.form-label {
  text-align: left;
  width: 100%;
  font-weight: bold;
}

input.form-control {
  @extend .mb-3;
}

.navbar {
  @extend .mb-3;
  background-color: black;
  color: white;

  h1 {
    width: 100%;
    font-weight: bold;
  }
}