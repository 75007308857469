.btn {
  @extend .my-2;
  width: 100%;
  box-shadow: 5px 5px 0px 0px rgba(0,0,0,.75);
  font-weight: bold;
  
  &:hover, &:active {
    box-shadow: none;
    color: white !important;
  }
}

.btn-back {
  @extend .btn-outline-danger;
}