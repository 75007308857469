.Minigame {
  .stats {
    @extend .gap-4;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    >div {
      flex-basis: 0;
      display: flex;
      flex-direction: column;

      .stat-label {
        @extend .text-muted;
      }
  
      .stat-value {
        @extend .h3;
      }
    }
  }
}