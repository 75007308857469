/* Overrides for Bootstrap SASS/SCSS variables */
@import "font_stack";
@import "colors";

$container-max-widths: (
  sm: 540px
);

$btn-border-width: 5px;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$btn-hover-bg-shade-amount:       0%;
$btn-hover-bg-tint-amount:        0%;
$btn-hover-border-shade-amount:   0%;
$btn-hover-border-tint-amount:    0%;
$btn-active-bg-shade-amount:      20%;
$btn-active-bg-tint-amount:       0%;
$btn-active-border-shade-amount:  20%;
$btn-active-border-tint-amount:   0%;